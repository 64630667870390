import { ParallaxProvider } from "react-scroll-parallax";
import ReactGA from "react-ga";
import Faq from "./Faq";
import Foodietown from "./Foodietown";
import Footer from "./Footer";
import Header from "./Header";
import Hero from "./Hero";
import Roadmap from "./Roadmap";
import "./styles.css";
import Utility from "./Utility";
import { toast, ToastContainer } from "react-toastify";
import { BrowserRouter, MemoryRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import MintApp from "./mint/MintApp";

export const DISCORD_LINK = "https://discord.gg/mFhS7rs8Cg";
export const TWITTER_LINK = "https://twitter.com/foodbotsnfts";
export const OPENSEA_LINK = "https://opensea.io/collection/foodbots-official";
export const SMART_CONTRACT_LINK =
  "https://etherscan.io/address/0x10f2E336E6634bf5737ef96D2f63F4551F235006";

export const BASE_PATH = ".";

ReactGA.initialize("UA-220115175-1");
ReactGA.pageview("/");

function App() {
  return (
    <div id="container" className="font-sans overflow-x-hidden">
      <MemoryRouter>
        <Routes>
          <Route
            // path="/mint"
            // path="/"
            element={
              <ParallaxProvider>
                <Header />
                <main>
                  <ToastContainer
                    autoClose={5000}
                    closeButton={false}
                    closeOnClick={true}
                    draggable={true}
                    draggablePercent={25}
                    hideProgressBar={false}
                    limit={2}
                    newestOnTop={false}
                    pauseOnFocusLoss={false}
                    pauseOnHover={false}
                    position={toast.POSITION.TOP_CENTER}
                    rtl={false}
                  />
                  <Hero />
                  <Foodietown />
                  <Utility />
                  <Roadmap />
                  <Faq />
                  {/* Secciones */}
                  <Footer />
                </main>
              </ParallaxProvider>
            }
          />
          <Route
            path="/"
            element={
              <>
                <ToastContainer
                  autoClose={5000}
                  closeButton={false}
                  closeOnClick={true}
                  draggable={true}
                  draggablePercent={25}
                  hideProgressBar={false}
                  limit={2}
                  newestOnTop={false}
                  pauseOnFocusLoss={false}
                  pauseOnHover={false}
                  position={toast.POSITION.TOP_CENTER}
                  rtl={false}
                />
                <MintApp />
              </>
            }
          />
        </Routes>
      </MemoryRouter>
    </div>
  );
}

export default App;
